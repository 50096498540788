import { Component, Input, ViewChild, AfterViewInit } from '@angular/core';
import { GlobalFunctions } from '@app/Global/GlobalFunctions';
import { animate, style, transition, trigger } from '@angular/animations';
import { ApiService } from '@app/Services/APIService';
import { NotifyService } from '@app/Services/NotifyService';
import { Router } from '@angular/router';
import { AccountsControllerMethods, UsersControllerMethods } from '@app/Global/EnumManager';
import { ClientDataStore } from '@app/Global/ClientDataStore';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DocViewer } from '../DocViewer/DocViewer';
import { ConfirmModal } from '../ConfirmModal/ConfirmModal';
import moment from 'moment';

@Component({
  selector: 'StatementGenerator',
  templateUrl: './StatementGenerator.html',
  styleUrls: ['./StatementGenerator.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('0.1s ease-out', style({ opacity: '1' })),
      ]),
    ]),
  ]
})

export class StatementGenerator implements AfterViewInit {

  //Constructor
  constructor(public globalFunctions: GlobalFunctions,
    private apiService: ApiService,
    private notifyService: NotifyService,
    private router: Router,
    private clientDataStore: ClientDataStore,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<StatementGenerator>) {
  }

  //Unique identifier for the launched modal
  @Input() ModalIdentifier;

  //Multiselect single Input
  @ViewChild('INP_MultiselectSingle') INPMultiselectSingle;

  public AccountID: string;
  public FromDate: Date;
  public ToDate: Date;
  public NowDate: Date;
  public ThresholdDays = -3;
  public MaxToDate: Date;
  public AllowBorrowerAccess: boolean = false;

  //For tracking if a server update request is in progress
  public IsGenerateInProgress = false;

  //To store the selected option
  public SelectedMultiselectSingleOptions = [];

  //List of available clients for users to select in the dropdown
  public ClientMultiselectOptions = [];

  //To store the list of clients linked to the account
  public LinkedClients = [];

  //This will be called after the view has been loaded
  ngAfterViewInit() {

    //We still need a short delay even this method is added on ngAfterViewInit
    this.globalFunctions.delay(50).then(() => {
      this.LinkedClients_Get();
    });
  }

  //Send the request to Generate the statement on the server
  public Statement_Generate(): void {
    //Let's do some basic client side validation
    //From and To Date must be selected. They must be a valid date
    if (this.globalFunctions.isEmpty(this.FromDate) || !this.globalFunctions.IsValidDate(this.FromDate)) {
      this.notifyService.Error_Show("Please select a valid From Date", "Error - From Date");
      return;
    }

    //To Date
    if (this.globalFunctions.isEmpty(this.ToDate) || !this.globalFunctions.IsValidDate(this.ToDate)) {
      this.notifyService.Error_Show("Please select a valid To Date", "Error - To Date");
      return;
    }

    //From date must be less than or equal to the To Date
    if (this.ToDate < this.FromDate) {
      this.notifyService.Error_Show("Please select a valid To Date", "Error - To Date must be after From Date");
      return;
    }

    //Validate the client selection
    if (this.globalFunctions.isEmpty(this.SelectedMultiselectSingleOptions)) {
      this.notifyService.Error_Show("Please select a client", "Error");
      return;
    }

    const selectedClient = this.SelectedMultiselectSingleOptions[0];
    if (this.globalFunctions.isEmpty(selectedClient) || this.globalFunctions.isEmpty(selectedClient.GUID)) {
      this.notifyService.Error_Show("Please select a client", "Error");
      return;
    }

    //Track state of this request, so that we can show the spinner, if necessary.
    this.IsGenerateInProgress = true;
    this.clientDataStore.SetShowFullscreenLoading(true);
    //console.log('this.FromDate', this.FromDate);

    //Construct the request and send it to the server
    const apiRequest = { AccountID: this.AccountID, FromDate: moment(this.FromDate).format('YYYY-MM-DD'), ToDate: moment(this.ToDate).format('YYYY-MM-DD'), AllowBorrowerAccess: this.AllowBorrowerAccess, ClientGUID: selectedClient.GUID };
    //console.log('apiRequest', apiRequest);

    this.apiService.APIData_Post(this.apiService.Endpoints.AccountsController, AccountsControllerMethods[AccountsControllerMethods.GenerateStatement], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {
          //There was no response, or an error.
          this.IsGenerateInProgress = false;
          this.clientDataStore.SetShowFullscreenLoading(false);
          return;
        }
        else {
          //Get the response, and try to display it in the document viewer
          const response = JSON.parse(JSON.stringify(apiResponse));
          //console.log('response', response);

          if (response.ProcessResult === true) {
            //Create a blob that can be used to represent the binary version of the file
            const blob = this.globalFunctions.base64toBlob(response.Base64Document);

            //Open DocViewer
            const docViewer = this.globalFunctions.FeatureModal_Launch(DocViewer, this.globalFunctions.GetFeatureModalConfig(), this.dialog, "Doc Viewer", this.AccountID, true, false);
            docViewer.DialogRef.componentInstance.FileBlob = blob;
            docViewer.DialogRef.componentInstance.FileName = response.Name + "." + response.Extension;
            docViewer.DialogRef.componentInstance.FileType = response.Extension;
            docViewer.DialogRef.componentInstance.OriginalFileType = response.Extension;

            this.globalFunctions.delay(1000).then(() => {

              //A notification would be nice
              this.notifyService.Success_Show("Statement Generated", "Success");
            });

          }
          else {
            //We didn't recieve the document. Its going to take too long to generate. Display a message using the confirmer
            const dialogRef = this.globalFunctions.FeatureModal_Launch(ConfirmModal, this.globalFunctions.GetConfirmModalConfig(), this.dialog, "Confirm Modal", this.AccountID, true, false);

            //Use html content so that we can style it
            dialogRef.DialogRef.componentInstance.htmlContent = response.ProcessMessage;

            //Hide the No Button
            dialogRef.DialogRef.componentInstance.ShowNoButton = false;

            //Change the Yes button text to "OK"
            dialogRef.DialogRef.componentInstance.YesButtonText = "Ok";

            //After the OK button is pressed, close this Statement Generator Modal too
            dialogRef.DialogRef.afterClosed().subscribe(result => {
              if (result === true) {

                //Close this modal
                this.globalFunctions.FeatureModal_Close(this.ModalIdentifier);

                //TODO we could also trigger navigation to the Document entity, if it exists. Can do this later
              }
            });
          }

          //Turn off the fullscreen loading
          this.clientDataStore.SetShowFullscreenLoading(false);
          this.IsGenerateInProgress = false;
        }
      });
  }

  //Send the request to get the max to date
  public Statement_GetMaxEndDate(): void {

    //Construct the request and send it to the server
    const apiRequest = { Date: moment(this.NowDate).format('YYYY-MM-DD'), NumberOfDays: this.ThresholdDays };

    this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.GetBusinessDate], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {

          //There was no response, or an error.
          return;
        }
        else {

          //Get and save the response date
          const response = JSON.parse(JSON.stringify(apiResponse));
          this.MaxToDate = response.ResponseDate;

          //Default To date to the response date and convert it to JS date
          this.ToDate = new Date(JSON.parse(JSON.stringify(this.MaxToDate)));

          //Set FromDate as the first day of the ToDate month, to prevent default FromDate being set after ToDate at the start of the new month
          this.FromDate = new Date(this.ToDate.getFullYear(), this.ToDate.getMonth(), 1);
        }
      });
  }

  //Input Date validation on change
  public Date_OnChange() {

    //Convert the MaxToDate to JS date for comparison
    const maxToDate = new Date(this.MaxToDate);

    //Check if the from date selected is after the max allowed
    if (this.FromDate > maxToDate) {
      this.notifyService.Error_Show("Please choose a date prior to 3 business days", "From Date Error");
      this.FromDate = maxToDate;
      return;
    }

    //Check if the to date selected is after the max allowed
    if (this.ToDate > maxToDate) {
      this.notifyService.Error_Show("Please choose a date prior to 3 business days", "To Date Error");
      this.ToDate = maxToDate;
      return;
    }
  }

  //Toggle when we an item is selected from the list. Allowed = Single only
  public MultiSelectSingle_Toggle(e) {

    //Empty the array if there is any selection option already
    if (!this.globalFunctions.isEmpty(this.SelectedMultiselectSingleOptions)) {
      this.SelectedMultiselectSingleOptions.length = 0;
      this.SelectedMultiselectSingleOptions = [];
    }

    if (!this.globalFunctions.isEmpty(e.itemValue)) {

      //Set the new value in the selection option
      this.SelectedMultiselectSingleOptions.push(e.itemValue);

      //Hide the panel on selection
      if (!this.globalFunctions.isEmpty(this.INPMultiselectSingle)) {
        this.INPMultiselectSingle.hide();
      }
    }
  }

  //Method to invoke API to get all linked individual clients
  private LinkedClients_Get(): void {

    //Track state of this request, so that we can show the spinner, if necessary.
    this.IsGenerateInProgress = true;
    this.clientDataStore.SetShowFullscreenLoading(true);

    //Construct the request and send it to the server
    const apiRequest = { AccountID: this.AccountID };

    this.apiService.APIData_Post(this.apiService.Endpoints.AccountsController, AccountsControllerMethods[AccountsControllerMethods.GetAccountLinkedOptedClients], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {

          //There was no response, or an error.
          this.IsGenerateInProgress = false;
          this.clientDataStore.SetShowFullscreenLoading(false);
          return;
        }
        else {
          //Get the response, and try to display it in the document viewer
          const response = JSON.parse(JSON.stringify(apiResponse));

          //Set the target clients with the server response
          this.LinkedClients = response.LinkedClients;

          //Now initialise the page
          this.Page_Init();

          //Turn off the fullscreen loading
          this.clientDataStore.SetShowFullscreenLoading(false);
          this.IsGenerateInProgress = false;
          return;
        }
      });
  }

  //Entry point to initialize the start and end dates
  private Page_Init(): void {

    //Initialise the client selection options: Add the linked clients for the multiselect option
    if (!this.globalFunctions.isEmpty(this.LinkedClients)) {

      //Clear the options first
      this.ClientMultiselectOptions = [];

      //Loop through each client and add to the multiselect options
      this.LinkedClients.forEach(client => {
        this.ClientMultiselectOptions.push({ name: this.globalFunctions.HTMLUnescape(client.DisplayName), GUID: client.GUID });
      });
    }

    //Now set the default dates
    //Set defaults for the From and To Date
    const date = new Date(), y = date.getFullYear(), m = date.getMonth(), d = date.getDate();

    //First day of the month
    const firstDay = new Date(y, m, 1);

    //Last day of the month
    //var lastDay = new Date(y, m + 1, 0);
    //Now (no time)
    this.NowDate = new Date(y, m, d);

    //Default From date to the start of the current month
    this.FromDate = firstDay;

    //Default To date to today (current date)
    this.ToDate = new Date(JSON.parse(JSON.stringify(this.NowDate)));

    //Determine if the user is an AMAL Employee 
    if (this.globalFunctions.Claim_VerifyAMALEmployee(this.clientDataStore) === true) {

      //Set max allowed date to today's date for AMAL Employees
      this.MaxToDate = this.NowDate;
    }
    else {

      //Get the Max allowed to date for non AMAL Employees
      this.Statement_GetMaxEndDate();
    }
  }
}