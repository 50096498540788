<div id="PG_CustomerVerification" class="glb_paddingBottomMD glb_formBGColor text-white glb_Font" [ngClass]="ModalIdentifier">

  <!-- close modal button. put this here on its own row, so that it stays put and won't bootstrap down to another row in smaller screen sizes -->
  <div class="glb_customFlexRow">
    <div class="glb_headerBarBGDark glb_customFlexRow justify-content-end col-12" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
      <div class="justify-content-start align-self-bottom">
        <button id="BTN_MinimizeCustomerVerification" tabindex="4"
          class="btn glb_clickableIconButtonStyleNone glb_clickableIconButtonColorDark glb_skinLightOutline" type="button"
          (click)="globalFunctions.MinimizedDialogs_Add(ModalIdentifier)">
          <fa-icon [icon]="['fal', 'compress-alt']" class="fa-md glb_iconColorDark" matTooltip="Click to minimize"></fa-icon>
        </button>
        <button id="BTN_CloseCustomerVerification" tabindex="30"
          class="btn glb_clickableIconButtonStyleNone glb_clickableIconButtonColorDark glb_skinLightOutline" type="button"
          (click)="globalFunctions.FeatureModal_Close(ModalIdentifier)">
          <fa-icon [icon]="['fal', 'times']" class="fa-md glb_iconColorDark"></fa-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="glb_customFlexRow glb_boxedPadding">
    <div class="glb_Font header-padding glb_customFlexRow">
      <div class="glb_customFlexRow col-12 glb_paddingZeroOffset">
        <div class="col-8 glb_paddingZeroOffset">
          <div class="glb_TitleLG glb_TitleLGMobile text-white text-start glb_breakLongWords glb_paddingZeroOffset glb_paddingBottomLG">
            Customer Verification
          </div>
        </div>

        <div class="glb_Font col-12 header-padding glb_customFlexRow glb_paddingBottomSM text-dark">

          <div class="col-12 glb_customFlexRow glb_paddingBottomSM">
            <div class="col-8 glb_customFlexRow">
              <!-- This is a primeng multiselect for choosing a target client-->
              <p-multiSelect class="col-12" #INP_MultiselectSingle id="DU_MultiselectSingle" [options]="ClientMultiselectOptions"
                [(ngModel)]="SelectedMultiselectSingleOptions" optionLabel="name" [appendTo]="'body'" [filter]="true" [virtualScroll]="true"
                [virtualScrollItemSize]="40" [resetFilterOnHide]="true" [selectAll]="false" [showToggleAll]="false" [showClear]="false"
                [showHeader]="true" [disabled]="false" placeholder="Choose a client" (onChange)="MultiSelectSingle_Toggle($event)">
              </p-multiSelect>
            </div>

            <div class="col-4 glb_boxedPaddingXS glb_customFlexRow glb_FontLabelDisplay glb_FontSizeSummary">
              <div class="col-12 justify-content-start glb_customFlexRow glb_LabelFontDark">
                <mat-checkbox id="CB_PartyNotListed" class="glb_Font mat-checkbox-dark-custom" [(ngModel)]="CallerNotListed"
                  (change)="CallerNotListed_Toggle($event)">
                  Not Listed
                </mat-checkbox>
              </div>
            </div>
          </div>

          <div class="col-12 glb_customFlexRow glb_paddingBottomSM">
            <div class="col-12 glb_customFlexRow glb_FontLabelDisplay glb_FontSizeSummary">
              <div class="col-4 col-lg-3 justify-content-start glb_customFlexRow glb_LabelFontDark">
                Call Type: </div>

              <div class="col-8 col-lg-8 glb_customFlexRow glb_Font">
                <p-selectButton id="EL_KYCInboundOutboundID" class="p-customChooseOptionsSM" [options]="CallTypeOptions" [ngModel]="CallTypeValue"
                  [multiple]="false" optionLabel="label" optionValue="value" [allowEmpty]="false"
                  (onChange)="CallTypeOption_Click($event)"></p-selectButton>
              </div>
            </div>
          </div>

          <!-- ID Checklist Items starts here based on chosen client -->
          <div *ngIf="ShowIDCheckList" class="col-12 glb_customFlexRow glb_paddingBottomSM">
            <div *ngFor="let checklist of TargetPartyIDCheckList_Get(SelectedClientGUID); let i=index"
              class="col-12 glb_customFlexRow glb_FontLabelDisplay glb_FontSizeSummary">
              <div *ngIf="checklist.Name !== ''" class="col-12 glb_customFlexRow">
                <div class="col-3 justify-content-start glb_customFlexRow glb_LabelFontDark">
                  <div>
                    {{checklist.Name}}:
                  </div>
                </div>
                <div class="col-6 glb_customFlexRow glb_Font">
                  <div *ngIf="checklist.CheckResult !== '1'" class="col-11 justify-content-start glb_customFlexRow text-white">
                    {{checklist.Value}}
                  </div>
                  <div *ngIf="checklist.CheckResult === '1'" class="col-11 justify-content-start glb_customFlexRow text-white">
                    <span class="glb_customFlexRow">{{checklist.Value}}
                      <fa-icon [icon]="['fal', 'badge-check']" class="glb_successColor glb_paddingLeftXS"></fa-icon>
                    </span>
                  </div>
                </div>
                <div class="col-3 glb_customFlexRow glb_Font glb_paddingBottomXS">
                  <div class="glb_customFlexRow">
                    <p-selectButton id="EL_PassFailID" class="p-customChooseOptionsSM" [options]="CheckResultOptions"
                      [ngModel]="checklist.CheckResult" [multiple]="false" optionLabel="label" optionValue="value" [allowEmpty]="false"
                      (onChange)="CheckResultOption_Click($event, checklist.GUID)"></p-selectButton>
                  </div>
                  <!-- Button to clear content inside the input box, spans a div of its own -->
                  <div class="justify-content-start clearButtonPadding">
                    <span>
                      <button tabindex="-1"
                        class="glb_clickableIconButtonStyleGrey glb_clickableIconButtonColorLight glb_clickableIconButtonMD glb_BGColorClear"
                        matTooltip="Click to Clear" (click)="CheckResult_Clear(checklist.GUID)">
                        <fa-icon [icon]="['fal', 'eraser']" class="fa-fw glb_fixedSizeIcons"></fa-icon>
                      </button>
                    </span>
                  </div>
                </div>
              </div>
              <!-- Let's add an empty div -->
              <div *ngIf="checklist.Name === ''" class="glb_emptyRowSM">
              </div>
            </div>
          </div>

          <!-- Checkboxes for successful/unsuccessful results-->
          <div class="col-12 glb_customFlexRow glb_paddingBottomSM">
            <div class="col-12 glb_customFlexRow glb_FontLabelDisplay glb_FontSizeSummary">
              <div *ngIf="ShowIDVerificationSuccessfulCheckbox" class="col-6 col-lg-3 justify-content-start glb_customFlexRow glb_LabelFontDark">
                <mat-checkbox id="CB_IDVerificationPassed" class="glb_Font mat-checkbox-dark-custom" [(ngModel)]="IDVerificationSuccessful"
                  [disabled]="true">
                  Identification Successful
                </mat-checkbox>
              </div>
              <div class="col-6 col-lg-9 glb_customFlexRow glb_Font glb_textAlignEnd">
                <mat-checkbox id="CB_IDVerificationFailed" class="glb_Font mat-checkbox-dark-custom" [(ngModel)]="IDVerificationFailed"
                  (change)="IDVerificationFailed_Toggle($event)" [disabled]="!TargetClientSelected">
                  Identification Failed
                </mat-checkbox>
              </div>
            </div>

            <div *ngIf="IDVerificationFailed === true"
              class="glb_customFlexRow glb_PaddingVerticalSM col-12 col-lg-8 justify-content-center align-items-center">

              <!-- Display failed message for the user -->
              <div class="col-12 justify-content-start glb_customFlexRow">

                <div class="glb_customFlexRow row-cols-1 col-11 glb_PaddingVerticalSM justify-content-start glb_TitleMD glb_importantTextSMOffWhite">
                  {{IdentificationFailedMessageDisplay}}
                </div>

              </div>

              <div class="col-12 glb_customFlexRow">
                <!-- Input for additional journal note -->
                <div class="col-11 glb_paddingZeroOffset">
                  <div class="glb_Font glb_customFlexRow text-white">Notes</div>
                  <textarea id="INP_Notes" class="textAreaCustom glb_Font textAreaHeightLarge glb_textAreaInputTextWrap"
                    placeholder="Additional notes" [(ngModel)]="FailedNotes" (keydown)="IDVerificationCheck_Sync()"
                    (keyup)="IDVerificationCheck_Sync()">
              </textarea>
                </div>

                <!-- Button to clear content inside the input box, spans a div of its own -->
                <div class="col-1 justify-content-start align-self-end clearButtonPadding">
                  <span>
                    <button tabindex="-1"
                      class="glb_clickableIconButtonStyleGrey glb_clickableIconButtonColorLight glb_clickableIconButtonMD glb_BGColorClear"
                      matTooltip="Click to Clear" (click)="FailedNotes_ClearData()">
                      <fa-icon [icon]="['fal', 'eraser']" class="fa-fw glb_fixedSizeIcons"></fa-icon>
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <!-- Save button -->
          <div class="text-white glb_customFlexRow row-cols-1 justify-content-left col-12">
            <div class="col-12 col-lg-8">
              <div class="glb_customFlexRow row-cols-1 justify-content-center align-items-center">
                <div *ngIf="!IsGenerateInProgress" class="glb_paddingBottomSM d-grid">
                  <button id="BTN_Complete" tabindex="19" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white"
                    [disabled]="!CompleteButtonEnabled" (click)="IDVerification_Complete()">
                    <span class="glb_Font glb_smallerText">Complete</span></button>
                </div>
                <div *ngIf="IsGenerateInProgress" class="glb_paddingBottomSM d-grid">
                  <button class="glb_standardButton btn glb_BGColorSkin text-white glb_positionRelative">
                    <span class="glb_Font glb_hiddenText glb_smallerText">Complete</span>
                    <div class="spinner-border glb_floatingSpinner"></div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>