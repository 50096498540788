//Store all enums in this class

//These are the page URL's in the client side application
export enum NavigationUrls {
	Home = "",
	Dashboard = "Dashboard",
	AccountSearch = "AccountSearch",
	PasswordReset = "PasswordReset",
	PasswordResetLink = "PasswordResetLink",
	ChangePassword = "ChangePassword",
	UserInvite = "UserInvite",
	TwoFactorReset = "TwoFactorReset",
	UserManagement = "UserManagement",
	UserProfile = "UserProfile",
	ReportBuilder = "ReportBuilder",
	RoleManagement = "RoleManagement",
	Documentation = "Documentation",
	WorkflowDelegator = "WorkflowDelegator",
	AccountManager = "AccountManager",
	Error = "Error",
	Account = "Account/:AccountID",
	LenderConfigurations = "LenderConfigurations",
	Settlements = "Settlements",
	SLAReporting = "SLAReporting",
	AccountSummaryConfigurations = "AccountSummaryConfigurations"
}

//All controllers available on the server, and their names. this could just be an enum?
export class Controllers {
	AccountsController: string
	UsersController: string
	StaticDataController: string

	constructor() {
		this.AccountsController = "Accounts";
		this.UsersController = "Users";
		this.StaticDataController = "StaticData";
	}
}

//Methods available on the Loans Controller
export enum AccountsControllerMethods {
	Search, GetAccountEntity, ModifyAccountEntity, GetLenderDashboardData, GetLenderAccountDashboardData, GetAccountNavTabs, ARMNetStep, ViewModelReporting, GetAccountSummary, GenerateStatement, GenerateDocumentFromTemplate, GetPayoutCalculation, SavePayoutCalculation, XV6Password_Reset, GetPendingSettlements, ValidatePendingSettlement, ProcessSettlement, AddTaskQualityFeedback, GetLeasePayoutCalculation, SaveLeasePayoutCalculation, GenerateLeasePayoutLetter, GetConditionSubsequentEmailPrefill, SendTaskEmail, FinaliseTask, AddTaskFollowup, GenerateResiPayoutLetter, GetResiRepaymentCalcData, CalculateResiRepaymentCalc, GetClientDetailsForIDVerification, CompleteCustomerVerification, GetAccountLinkedOptedClients
}

//Methods available on the Users Controller
export enum UsersControllerMethods {
	Login, Logout, InviteUser, SetupGoogleAuthentication, PasswordResetLink, PasswordResetLinkForUser, PasswordReset, ChangePassword, UserRegistration, GetUsers, GetRoles, GetRoleClaims, GetUserData, UpdateRoleClaim, UpdateRoleName, UpdateClaimEntity, UpdateClaimAssociationLinkType, UpdateClaimAssociationClient, CreateRole, CloneRole, CreateUser, UpdateUser, DeleteUser, DeleteRole, GetRoleAssignedUsers, CreateClaim, DeleteClaim, AssignUserRole, UnassignUserRole, GetDelegatorDashboard, ReassignTask, GetAccountManagerDashboard, GetQualityCheckingQueue, CreateBugReport, RefreshToken, GetLenderTasksDashboard, GetAccountEnquiriesDashboard, AssignUserLinkTypeFilter, RemoveUserLinkTypeFilter, UserOptions_Update, RoleClaim_UpdateValue, TwoFactorSetup, GetTaskDueDate, GetSLAReporting, GetSLAReportingDrillthrough, SLAReporting_RefreshCache, SLAReporting_GetRefreshedDate, GetBusinessDate, GetConditionSubsequentAggregateDashboard, GetConditionSubsequentDetailDashboard, RoleClaim_UpdateEntityPropertyFilter
}

//Methods available on the StaticData Controller
export enum StaticDataControllerMethods {
	GetDate, ControlDataCacheGUID, GetControlData, GetClientViewModel, GetEntityTypes, AutoComplete, AutoComplete_Client, AutoComplete_Role, AutoComplete_Employee, GetLenderConfigurations, CreateLenderConfiguration, UpdateLenderConfiguration, DeleteLenderConfiguration, CreateLenderConfigurationTaskAssignee, GetLenderConfigurationTaskAssignee, DeleteLenderConfigurationTaskAssignee, UpdateLenderConfigurationTaskAssignee, UpdateLenderConfigurationTaskAssigneeAlternateMode, ThrowError, GetLenderConfiguration, GetLenderConfigurationTaskNotifier, UpdateTaskNotifier, DeleteTaskNotifier, CreateTaskNotifier, GetAccountSummaryConfigurations, CreateAccountSummaryConfiguration, GetAccountSummaryConfigurationDetails, UpdateAccountSummaryConfigurationDetails, CloneAccountSummaryConfiguration, DeleteAccountSummaryConfiguration, UpdateAccountSummaryConfigurationName, GetRoleSummaryConfiguration, CreateRoleSummaryConfiguration, DeleteRoleSummaryConfiguration
}

export enum RouteActions {
	ReturnToSearchResults, ReturnToSearch, ReturnToDashboard
}


//Response status codes from the server. use these to determine how to direct responses generically. 
//OK = allow request control to pass back to the caller. Error = control does NOT pass back to caller, instead redirects to error page. 
//NonFatalWarning = allows request to pass back to caller, but a toast with an error message will be displayed. 
//SessionExpired = cancels caller request and launces the loginmodal (requesting another access token, user must log in again)
export enum APIResponseStatus {
	Ok, Error, NonFatalWarning, SessionExpired
}

//Request Types
export enum RequestTypes {
	Update, Delete, Create
}