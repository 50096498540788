import { Component, Input, ViewChild, AfterViewInit } from '@angular/core';
import { GlobalFunctions } from '@app/Global/GlobalFunctions';
import { animate, style, transition, trigger } from '@angular/animations';
import { ApiService } from '@app/Services/APIService';
import { NotifyService } from '@app/Services/NotifyService';
import { Router } from '@angular/router';
import { ClientDataStore } from '@app/Global/ClientDataStore';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AccountsControllerMethods } from '@app/Global/EnumManager';
import { LoanIndex } from '@app/Components/Loan/LoanIndex/LoanIndex';

@Component({
  selector: 'CustomerVerification',
  templateUrl: './CustomerVerification.html',
  styleUrls: ['./CustomerVerification.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('0.1s ease-out', style({ opacity: '1' })),
      ]),
    ]),
  ]
})

export class CustomerVerification implements AfterViewInit {

  //Constructor
  constructor(public globalFunctions: GlobalFunctions,
    private apiService: ApiService,
    private notifyService: NotifyService,
    private router: Router,
    private clientDataStore: ClientDataStore,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<CustomerVerification>) {
  }

  //Unique identifier for the launched modal
  @Input() ModalIdentifier;

  //Loan Index
  @Input() LoanIndex: LoanIndex;

  //Multiselect single Input
  @ViewChild('INP_MultiselectSingle') INPMultiselectSingle;

  //For tracking if a server update request is in progress
  public IsGenerateInProgress = false;

  //List of IDs to check
  public IDCheckList = [];

  //To store the list of clients linked to the account
  public LinkedClients = [];

  //GUID of the selected client
  public SelectedClientGUID = "";

  //Flag to indicate whether the client has been selected or not
  public TargetClientSelected = false;

  //List of available clients for users to select in the dropdown
  public ClientMultiselectOptions = [];

  //To store the selected option
  public SelectedMultiselectSingleOptions = [];

  //Flags
  public CallerNotListed = false;
  public ShowIDVerificationSuccessfulCheckbox = true;
  public IDVerificationSuccessful = false;
  public IDVerificationFailed = false;
  public CompleteButtonEnabled = false;
  public ShowIDCheckList = false;

  //Verification failed notes
  public FailedNotes = "";

  //For displaying the verification failed messages
  public IdentificationFailedMessageDisplay = "";
  public ShowIdentificationFailedMessage = false;

  //Call type option: inbound or outbound
  public CallTypeOptions: any[] = [{ label: 'Inbound', value: '0' }, { label: 'Outbound', value: '1' }];

  //Default Call Type to empty, no selection yet
  public CallTypeValue = "";

  //ID Check result type option: inbound or outbound
  public CheckResultOptions: any[] = [{ label: 'Fail', value: '0' }, { label: 'Pass', value: '1' }];

  public InitialPageLoad = true;

  //For initialising the page
  public Page_Init() {

    //Add the linked clients for the multiselect option
    if (!this.globalFunctions.isEmpty(this.LinkedClients)) {

      //Clear the options first
      this.ClientMultiselectOptions = [];

      //Loop through each client and add to the multiselect options
      this.LinkedClients.forEach(client => {
        this.ClientMultiselectOptions.push({ name: this.globalFunctions.HTMLUnescape(client.DisplayName), GUID: client.ClientGUID });
      });
    }

    //Initialise the ID check data for each target individual client
    this.LinkedClients.forEach(party => {
      this.IDCheckList.push({ GUID: this.globalFunctions.GenerateFastGUID(), PartyGUID: party.ClientGUID, Name: "Name", Value: party.Name, CheckResult: "" });
      this.IDCheckList.push({ GUID: this.globalFunctions.GenerateFastGUID(), PartyGUID: party.ClientGUID, Name: "DOB", Value: party.DOB, CheckResult: "" });
      this.IDCheckList.push({ GUID: this.globalFunctions.GenerateFastGUID(), PartyGUID: party.ClientGUID, Name: party.AddressType + " Address", Value: party.Address, CheckResult: "" });
      this.IDCheckList.push({ GUID: this.globalFunctions.GenerateFastGUID(), PartyGUID: party.ClientGUID, Name: "Mobile", Value: party.Phone, CheckResult: "" });
      this.IDCheckList.push({ GUID: this.globalFunctions.GenerateFastGUID(), PartyGUID: party.ClientGUID, Name: "Email", Value: party.Email, CheckResult: "" });

      //For adding an empty row
      this.IDCheckList.push({ GUID: this.globalFunctions.GenerateFastGUID(), PartyGUID: party.ClientGUID, Name: "", Value: "", CheckResult: "" });

      //Add security addresses
      if (!this.globalFunctions.isEmpty(party.Securities)) {

        let addressCounter = 1;
        party.Securities.forEach(address => {
          let addressLabel = "Security " + addressCounter.toString();

          //Add counter only if we have more than one security property
          if (party.Securities.length === 1) {
            addressLabel = "Security";
          }

          //Add to the checklist
          this.IDCheckList.push({ GUID: this.globalFunctions.GenerateFastGUID(), PartyGUID: party.ClientGUID, Name: addressLabel, Value: address, CheckResult: "" });

          //Increment the counter
          addressCounter++;
        });
      }

      //Bank Name
      this.IDCheckList.push({ Id: this.globalFunctions.GenerateFastGUID(), PartyGUID: party.ClientGUID, Name: "Bank Payments Drawn From", Value: party.LinkedBankName, CheckResult: "" });
    });
  }

  //This will be called after the view has been loaded
  ngAfterViewInit() {

    //Run it on initial load only
    if (this.InitialPageLoad) {

      //We still need a short delay even this method is added on ngAfterViewInit
      this.globalFunctions.delay(50).then(() => {
        this.LinkedIndividualClients_Get();

        //Set class variable initialPageLoad to false so that it won't force the payout date to current date
        this.InitialPageLoad = false;
      });
    }
  }

  //Action when a calltype option is selected
  public CallTypeOption_Click(event): void {

    //Set the view type value
    this.CallTypeValue = event.value;

    //Sync the identification check
    this.IDVerificationCheck_Sync();
  }

  //Triggers when a pass/fail action is selected
  public CheckResultOption_Click(event, guid): void {

    const matchingIDCheckRow = this.IDCheckList.filter(x => x.GUID === guid)[0];
    if (!this.globalFunctions.isEmpty(matchingIDCheckRow)) {
      matchingIDCheckRow.CheckResult = event.value;
    }

    //Sync the identification check
    this.IDVerificationCheck_Sync();
  }

  //Clears the selection
  public CheckResult_Clear(guid): void {

    const matchingIDCheckRow = this.IDCheckList.filter(x => x.GUID === guid)[0];

    if (!this.globalFunctions.isEmpty(matchingIDCheckRow)) {
      matchingIDCheckRow.CheckResult = "";
    }
  }

  //Method to invoke API to get all linked individual clients
  public LinkedIndividualClients_Get(): void {

    //Track state of this request, so that we can show the spinner, if necessary.
    this.IsGenerateInProgress = true;
    this.clientDataStore.SetShowFullscreenLoading(true);

    //Construct the request and send it to the server
    const apiRequest = { AccountID: this.LoanIndex.AccountID };

    this.apiService.APIData_Post(this.apiService.Endpoints.AccountsController, AccountsControllerMethods[AccountsControllerMethods.GetClientDetailsForIDVerification], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {

          //There was no response, or an error.
          this.IsGenerateInProgress = false;
          this.clientDataStore.SetShowFullscreenLoading(false);
          return;
        }
        else {
          //Get the response, and try to display it in the document viewer
          const response = JSON.parse(JSON.stringify(apiResponse));

          //Set the target clients with the server response
          this.LinkedClients = response.LinkedParties;

          //Now initialise the page
          this.Page_Init();

          //Turn off the fullscreen loading
          this.clientDataStore.SetShowFullscreenLoading(false);
          this.IsGenerateInProgress = false;
          return;
        }
      });
  }

  //To display the checklist data/value for verification
  public TargetPartyIDCheckList_Get(partyGUID: string) {

    if (!this.globalFunctions.isEmpty(this.IDCheckList)) {
      return this.IDCheckList.filter(x => x.PartyGUID === partyGUID);
    }
  }

  //Store if the user checks the id failed checkbox
  public IDVerificationFailed_Toggle(event) {

    //Set the checkbox flag
    this.IDVerificationFailed = event.checked;

    //Check if the verification failed
    if (this.IDVerificationFailed === true) {

      //Toggle the successful/failed checkbox
      this.IDVerificationSuccessful = false;
      this.ShowIDVerificationSuccessfulCheckbox = false;

      //Display identification failed message
      this.IdentificationFailedMessageDisplay = "The individual could not be successfully identified. Please inform them that you are unable to proceed with the enquiry.";
    }
    else {

      //Show the verification successful checkbox
      this.ShowIDVerificationSuccessfulCheckbox = true;
    }

    //Call sync method
    this.IDVerificationCheck_Sync();
  }

  //Store if the user checks that the caller is not listed in the dropdown
  public CallerNotListed_Toggle(event) {

    //Initialise the checklist properties
    this.IDCheckList_Init();

    //Check if the verification failed
    if (event.checked === true) {

      //Verification failed, set the display message as well
      this.CallerNotListed = true;
      this.IDVerificationFailed = true;
      this.ShowIDVerificationSuccessfulCheckbox = false;
      this.IdentificationFailedMessageDisplay = "The individual is not a party to the facility and, therefore, cannot be provided with information in accordance with the Privacy Policy.";
    }

    //Call sync method
    this.IDVerificationCheck_Sync();
  }
  //Sync method to check whether the ID verification is successful or not
  public IDVerificationCheck_Sync() {

    //Reset the flags
    this.IDVerificationSuccessful = false;
    this.CompleteButtonEnabled = false;

    //Check if the user has failed the verification
    if (this.IDVerificationFailed === false) {

      //Check if any of the 3 id's are verified
      const checkedIDs = this.IDCheckList.filter(x => x.CheckResult === "1" && x.PartyGUID === this.SelectedClientGUID);
      if (!this.globalFunctions.isEmpty(checkedIDs)) {
        if (checkedIDs.length >= 3) {
          this.IDVerificationSuccessful = true;
        }
      }
    }

    //User must select a call type
    if (this.CallTypeValue === "0" || this.CallTypeValue === "1") {

      //Enable the button after confirming whether the ID verification is successful or not
      if ((this.IDVerificationFailed === true && this.FailedNotes != null && this.FailedNotes.length > 3 && this.CompleteButtonEnabled === false) || this.IDVerificationSuccessful === true) {
        this.CompleteButtonEnabled = true
      }
    }
  }

  //Clear failed notes
  public FailedNotes_ClearData() {

    //Reset the notes
    this.FailedNotes = "";

    //Sync the verification check
    this.IDVerificationCheck_Sync();
  }

  //To initialise the verification checklist
  public IDCheckList_Init(): void {

    //Reset the verification properties
    this.ShowIDCheckList = false;
    this.SelectedClientGUID = "";
    this.IDVerificationFailed = false;
    this.IDVerificationSuccessful = false;
    this.IdentificationFailedMessageDisplay = "";
    this.FailedNotes = "";
    this.TargetClientSelected = false;
    this.CallerNotListed = false;
    this.ShowIDVerificationSuccessfulCheckbox = true;

    //Empty the array if there is any selection option already
    if (!this.globalFunctions.isEmpty(this.SelectedMultiselectSingleOptions)) {
      this.SelectedMultiselectSingleOptions.length = 0;
      this.SelectedMultiselectSingleOptions = [];
    }

    //Reset the verified checklist
    this.IDCheckList.forEach(idCheckList => {
      idCheckList.CheckResult = "";
    });
  }

  //Toggle when we an item is selected from the list. Allowed = Single only
  public MultiSelectSingle_Toggle(e) {

    //Initialise the checklist properties
    this.IDCheckList_Init();

    if (!this.globalFunctions.isEmpty(e.itemValue)) {

      //Client selected
      this.TargetClientSelected = true;

      //Set the new value in the selection option
      this.SelectedMultiselectSingleOptions.push(e.itemValue);

      if (!this.globalFunctions.isEmpty(e.itemValue.GUID)) {

        this.SelectedClientGUID = e.itemValue.GUID;

        //User selected the valid client, lets display the ID checklist details
        this.ShowIDCheckList = true;
      }

      //Hide the panel on selection
      if (!this.globalFunctions.isEmpty(this.INPMultiselectSingle)) {
        this.INPMultiselectSingle.hide();
      }
    }

    //Sync the verification check after the new client is selected
    this.IDVerificationCheck_Sync();
  }

  //ID Verification check completion
  public IDVerification_Complete(): void {

    //We must know whether the call is Inbound or Outbound
    if (this.globalFunctions.isEmpty(this.CallTypeValue)) {
      this.notifyService.Error_Show("Please choose a call type", "Error");
      return;
    }

    //For storing the target Client GUID and name
    let clientGUID = "";
    let clientName = "";

    //See if the individual is not listed
    if (!this.CallerNotListed) {

      //Matching target client
      const targetLinkedClient = this.LinkedClients.filter(x => x.ClientGUID === this.SelectedClientGUID)[0];
      if (this.globalFunctions.isEmpty(targetLinkedClient)) {

        //No matching client found
        this.notifyService.Error_Show("Please select a target client", "Error");
        return;
      }

      //Set the target client guid
      clientGUID = targetLinkedClient.ClientGUID;
      clientName = targetLinkedClient.Name;
    }

    //Whether the call is of type inbound or outbound
    let isInboundCall: boolean;

    //Check whether the call is of type outbound. O = Inbound, 1 = Outbound
    if (this.CallTypeValue === "1") {
      isInboundCall = false;
    }
    else if (this.CallTypeValue === "0") {
      isInboundCall = true;
    }
    else {
      this.notifyService.Error_Show("Please choose a call type", "Error");
      return;
    }

    //Whether the verification is successful or not
    let isClientVerificationSuccessful: boolean;

    //Check if the id verification is successful
    if (this.IDVerificationSuccessful) {
      isClientVerificationSuccessful = true;
    }

    //Check for verification failures 
    if (this.IDVerificationFailed || this.CallerNotListed) {
      isClientVerificationSuccessful = false;
    }

    //To store the successfully/unsuccessfully answered questions
    const passedQuestions = [];
    const failedQuestions = [];

    //Get the list of successfully answered questions
    const passedIDCheckList = this.IDCheckList.filter(x => x.CheckResult === "1" && x.PartyGUID === this.SelectedClientGUID);

    //Get the list of questions failed to answer
    const failedIDCheckList = this.IDCheckList.filter(x => x.CheckResult === "0" && x.PartyGUID === this.SelectedClientGUID);

    //Add the successfully answered questions into a list
    if (!this.globalFunctions.isEmpty(passedIDCheckList)) {
      passedIDCheckList.forEach(checkedID => {
        passedQuestions.push(checkedID.Name);
      });
    }

    //Add the questions failed to answer into a list
    if (!this.globalFunctions.isEmpty(failedIDCheckList)) {
      failedIDCheckList.forEach(checkedID => {
        failedQuestions.push(checkedID.Name);
      });
    }

    //Load the spinner now
    this.IsGenerateInProgress = true;
    this.clientDataStore.SetShowFullscreenLoading(true);

    //Construct the request and send it to the server
    const apiRequest = { AccountID: this.LoanIndex.AccountID, ClientGUID: clientGUID, IsInbound: isInboundCall, IsClientVerificationSuccessful: isClientVerificationSuccessful, IndividualNotListed: this.CallerNotListed, AdditionalNotes: this.FailedNotes, PassedQuestions: passedQuestions, FailedQuestions: failedQuestions };

    //Invoke API
    this.apiService.APIData_Post(this.apiService.Endpoints.AccountsController, AccountsControllerMethods[AccountsControllerMethods.CompleteCustomerVerification], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {

          //There was no response, or an error.
          this.IsGenerateInProgress = false;
          this.clientDataStore.SetShowFullscreenLoading(false);
          return;
        }
        else {
          //Get the response, and try to display it in the document viewer
          const response = JSON.parse(JSON.stringify(apiResponse));

          //Let's just send a toast message for now
          this.notifyService.Success_Show(response.ResponseMessage, "Success");

          //Let's launch a notes screen for additional input notes if verification is successful
          if (this.IDVerificationSuccessful === true) {

            //Construct a journal note for further auditing
            const journalNoteTemplate = clientName + " successfully identified by 3 security questions";

            //Launch a notes screen entity with journal note template included
            this.LoanIndex.Entity_OpenModifyTemplate(null, '', 'LoanNotes', null, null, null, null, null, "Added to the Notes tab.", journalNoteTemplate);
          }

          //Close the modal
          this.globalFunctions.FeatureModal_Close(this.ModalIdentifier);

          //Turn off the fullscreen loading
          this.clientDataStore.SetShowFullscreenLoading(false);
          this.IsGenerateInProgress = false;

          return;
        }
      });

    //Get the outcome details

    // let checkResult = "Unsuccessful";

    // if (this.CallerNotListed === true) {
    //   checkResult = "Unsuccessful (Not Listed)";
    // }
    // else {

    //   if (this.IDVerificationSuccessful === true) {
    //     checkResult = "Successful";
    //   }

    //   const checkedIDs = this.IDCheckList.filter(x => x.CheckResult === "1" && x.PartyGUID === this.SelectedClientGUID);
    //   const failedIDs = this.IDCheckList.filter(x => x.CheckResult === "0" && x.PartyGUID === this.SelectedClientGUID);

    //   let checkPassedIDList = "";
    //   let checkPassedIDConfirmation = "";
    //   let checkFailedIDList = "";
    //   let checkFailedIDConfirmation = "";
    //   const targetLinkedClient = this.LinkedClients.filter(x => x.ClientGUID === this.SelectedClientGUID)[0];
    //   if (this.globalFunctions.isEmpty(targetLinkedClient)) {
    //     this.notifyService.Error_Show("Please select a target client", "Error");
    //   }

    //   if (!this.globalFunctions.isEmpty(checkedIDs)) {
    //     checkedIDs.forEach(checkedID => {
    //       checkPassedIDList += "<li class=\"col-12\"> " + checkedID.Name + " " + checkedID.Value + "</li>";
    //     });
    //   }

    //   if (!this.globalFunctions.isEmpty(checkPassedIDList)) {
    //     checkPassedIDConfirmation = "<p>Verification passed checks: </p>"
    //       + "<ul class=\"glb_customFlexRow align-items-center\" style=\"margin-left: 1em; text-align: left;\">"
    //       + checkPassedIDList
    //       + "</ul>"
    //       + "<br>";
    //   }

    //   if (!this.globalFunctions.isEmpty(failedIDs)) {
    //     failedIDs.forEach(failedID => {
    //       checkFailedIDList += "<li class=\"col-12\"> " + failedID.Name + " " + failedID.Value + "</li>";
    //     });
    //   }

    //   if (!this.globalFunctions.isEmpty(checkFailedIDList)) {
    //     checkFailedIDConfirmation = "<p>Verification failed checks: </p>"
    //       + "<ul class=\"glb_customFlexRow align-items-center\" style=\"margin-left: 1em; text-align: left;\">"
    //       + checkFailedIDList
    //       + "</ul>"
    //   }
    // }
  }
}