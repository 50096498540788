import { Component, Input, OnInit } from '@angular/core';
import { StaticDataControllerMethods } from '@app/Global/EnumManager';
import { GlobalFunctions } from '@app/Global/GlobalFunctions';
import { ApiService } from '@app/Services/APIService';
import { NotifyService } from '@app/Services/NotifyService';
import { TemplateID } from '@app/Global/Models/ClientModels';
import { DataUnit, ControlData, EntityTypesDisplay } from '@app/Global/Models/EntityModels';
import { ClientDataStore } from '@app/Global/ClientDataStore';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-home',
  templateUrl: './Documentation.html',
  styleUrls: ['./Documentation.scss']
})

export class Documentation implements OnInit {

  constructor(public globalFunctions: GlobalFunctions, private apiService: ApiService, private notifyService: NotifyService, private clientDataStore: ClientDataStore, private dialogRef: MatDialogRef<Documentation>) {
    this.EntityTypesDisplay = [];
    this.EntityTypesDisplay.push({ Name: "Loan Details", NavDisplayName: "Loan Details" });
    this.EntityTypesDisplay.push({ Name: "Associations", NavDisplayName: "Associations" });
    this.EntityTypesDisplay.push({ Name: "Pending Tasks", NavDisplayName: "Pending Tasks" });
    this.EntityTypesDisplay.push({ Name: "User Tasks", NavDisplayName: "User Tasks" });
    this.EntityTypesDisplay.push({ Name: "Transactions", NavDisplayName: "Transactions" });
    this.EntityTypesDisplay.push({ Name: "Dates", NavDisplayName: "Dates" });
    this.EntityTypesDisplay.push({ Name: "Contract Payments", NavDisplayName: "Contract Payments" });

    //Declare DataUnit for the numeric sample
    this.DataUnitNumericSample = new DataUnit();

    //Set the values for this example
    this.DataUnitNumericSample.Type = "decimal.2";
    this.DataUnitNumericSample.Name = "Bob";
    this.DataUnitNumericSample.Value = "12.52";

    //Declare DataUnit for the autocomplete sample
    this.DataUnit = new DataUnit();
    this.DataUnit.ControlGUID = "";
    this.DataUnit.ControlValue = "";
    this.DataUnit.Name = "Suburb";
    this.DataUnit.AutoCompleteLastSearchedValue = "";
    this.DataUnit.AutoCompleteControlData = [];

    //Fill sample control data
    this.SampleControlData_Fill();
  }

  //For displaying entity types
  public EntityTypesDisplay: EntityTypesDisplay[];
  //Pretty app name for displaying on the page
  public AppName = this.globalFunctions.GetApplicationName();
  //Fake dataunit for the documentation
  public DataUnit: DataUnit;
  //Fake control data for autocomplete
  public ControlData: ControlData[] = [];
  //Used for the numeric sample
  public DataUnitNumericSample: DataUnit;

  //User Guide View Claims
  public StatementGeneratorClaim = false;
  public DocumentGeneratorClaim = false;
  public PayoutGeneratorClaim = false;
  public ResiRepaymentClaim = false;
  public LeasePayoutClaim = false;
  public BorrowerUserManagementClaim = false;
  public LenderUserManagementClaim = false;
  public AccountEnquiryClaim = false;
  public InsuranceEditClaim = false;
  public BankDetailsEditClaim = false;
  public EditableAccountStatusClaim = false;
  public AssocClientEditClaim = false;
  public ViewAccountSummaryClaim = false;

  //Unique identifier for the launched modal
  @Input() ModalIdentifier: string;

  //Array to store the identifier GUIDs
  private TemplateIdentifiers: TemplateID[] = [];
  //For tracking the click on Loan Search Bar nab tabs
  private CurrentItemIndex = 0;
  //Unique GUID to track the last autocomplete request
  private LastAutoCompleteRequestGUID: string;

  //DateTime picker format
  public DTPDateFormat = "dd/MM/yy";

  //Screenshot images
  public ScreenshotsPath = [];
  public FolderPath = "/LenderClient/assets/screenshots/";
  public FileExtension = "jpg";

  ngOnInit(): void {

    //Check for the claims to display the user guide
    this.StatementGeneratorClaim = this.globalFunctions.Claim_VerifyPermission(this.clientDataStore, "StatementGenerator", "Read");
    this.DocumentGeneratorClaim = this.globalFunctions.Claim_VerifyPermission(this.clientDataStore, "DocumentGenerator", "Read");
    this.PayoutGeneratorClaim = this.globalFunctions.Claim_VerifyPermission(this.clientDataStore, "PayoutCalculator", "Read");
    this.ResiRepaymentClaim = this.globalFunctions.Claim_VerifyPermission(this.clientDataStore, "ResidentialRepaymentCalculator", "Read");
    this.LeasePayoutClaim = this.globalFunctions.Claim_VerifyPermission(this.clientDataStore, "LeasePayoutCalculator", "Read");
    this.BorrowerUserManagementClaim = this.globalFunctions.Claim_VerifyPermission(this.clientDataStore, "ResetBorrowerPassword", "Read");
    this.LenderUserManagementClaim = this.globalFunctions.Claim_VerifyPermission(this.clientDataStore, "UserAdministrator", "Read");
    this.AccountEnquiryClaim = this.globalFunctions.Claim_VerifyPermission(this.clientDataStore, "AccountEnquiries", "Read");
    this.InsuranceEditClaim = (this.globalFunctions.EntityClaim_VerifyPermission(this.clientDataStore, "All", "Edit") || this.globalFunctions.EntityClaim_VerifyPermission(this.clientDataStore, "Insurance Policy", "Edit") || this.globalFunctions.EntityPropertyClaim_VerifyPermission(this.clientDataStore, "Insurance Policy", "Expiry Date", "Edit"));
    this.BankDetailsEditClaim =
      (this.globalFunctions.EntityClaim_VerifyPermission(this.clientDataStore, "All", "Edit") || this.globalFunctions.EntityClaim_VerifyPermission(this.clientDataStore, "Bank Account", "Edit") || this.globalFunctions.EntityPropertyClaim_VerifyPermission(this.clientDataStore, "Bank Account", "Account Number", "Edit"));
    this.EditableAccountStatusClaim =
      (this.globalFunctions.EntityClaim_VerifyPermission(this.clientDataStore, "All", "Edit") || this.globalFunctions.EntityClaim_VerifyPermission(this.clientDataStore, "Editable Account Status", "Edit") || this.globalFunctions.EntityPropertyClaim_VerifyPermission(this.clientDataStore, "Account Detail", "Account Status", "Edit"));
    this.AssocClientEditClaim =
      (this.globalFunctions.EntityClaim_VerifyPermission(this.clientDataStore, "All", "Edit") || this.globalFunctions.EntityClaim_VerifyPermission(this.clientDataStore, "Assoc Client Editing", "Edit") || this.globalFunctions.EntityPropertyClaim_VerifyPermission(this.clientDataStore, "Association", "Client", "Edit"));
    this.ViewAccountSummaryClaim = this.globalFunctions.Claim_VerifyPermission(this.clientDataStore, "ViewAccountSummary", "Read");

    this.ScreenshotsPath.push(this.FolderPath + "ResidentialPayoutCalculator/");
    this.ScreenshotsPath.push(this.FolderPath + "AccountEnquiry/");
    this.ScreenshotsPath.push(this.FolderPath + "InsuranceEdit/");
    this.ScreenshotsPath.push(this.FolderPath + "BankDetails/");
    this.ScreenshotsPath.push(this.FolderPath + "ResiRepaymentCalc/");
    this.ScreenshotsPath.push(this.FolderPath + "AccountStatusEditing/");
    this.ScreenshotsPath.push(this.FolderPath + "AssocClientUpdate/");
    this.ScreenshotsPath.push(this.FolderPath + "AccountSummary/");
  }

  //Hide the datetime picker panel
  public DTP_Close(calendar): void {
    calendar.hideOverlay();
  }

  //Find and Toggle the IsEnabled Flag on the Template identifier
  public TemplateID_Toggle(identifierID: string): void {
    this.globalFunctions.TemplateID_Toggle(identifierID, this.TemplateIdentifiers)
  }

  //Get the css class based on the Template identifier state which drives if it should be displayed or not
  public TemplateID_GetCSS(identifierID: string, inverted = false): string {
    return (this.globalFunctions.TemplateID_GetCSS(identifierID, inverted, this.TemplateIdentifiers));
  }

  //For setting the current clicked nav item
  public CurrentLoanSearch_SetItemIndex(itemIndex: number) {
    this.CurrentItemIndex = itemIndex;
  }

  //For activating the clicked nav item index. Activate first item by default
  public NavTab_GetClass(itemIndex: number) {
    let returnClass = "";
    if (itemIndex === this.CurrentItemIndex) {
      //This is needed to dynamically set the first nav tab as active
      returnClass += " show active";
    }

    return returnClass;
  }

  //Sample control data for display on the page
  private SampleControlData_Fill() {
    this.ControlData.push({ ControlGUID: "{sampleControlValue}", ControlValue: "Sample Dropdown Value", ControlType: "", ControlDisplay: "", Entity: "" });
    this.ControlData.push({
      ControlGUID: "{sampleControlValue2}", ControlValue: "Sample Dropdown Value 2", ControlType: "", ControlDisplay: "", Entity: ""
    });
    this.ControlData.push({
      ControlGUID: "{sampleControlValue3}", ControlValue: "Loan Purpose 1", ControlType: "", ControlDisplay: "", Entity: ""
    });
    this.ControlData.push({
      ControlGUID: "{sampleControlValue4}", ControlValue: "Loan Purpose 2", ControlType: "", ControlDisplay: "", Entity: ""
    });
  }

  //In relation to autocomplete - read the response, return the ControlValue out of it. this allows autocomplete to show the 'pretty' value in the input box
  public AutoComplete_GetPrettyName(value: ControlData): string {
    if (value.ControlValue != null) {
      return value.ControlValue;
    }
    else {
      return "";
    }
  }

  //Asks the server to provide a filtered list of autocomplete data, based on the supplied text (controlValue)
  public AutoComplete_ApplyFilter(dataUnit: DataUnit, requestType: string, controlValue: string) {
    //Ask server for values. only if we have a new value. if no value, don't try to retrieve anything from the server
    if (controlValue === null) {
      return;
    }

    if (dataUnit.AutoCompleteLastSearchedValue != null) {
      if ((dataUnit.AutoCompleteLastSearchedValue.toUpperCase() != controlValue.toUpperCase()) || requestType === "ControlGUID") {
        //Request new data and update the current value so we don't request it again.
        dataUnit.AutoCompleteLastSearchedValue = controlValue;
        //console.log("ReturnAutoCompleteData is requesting data");

        //Let's timestamp this request. that way, when a old update responds slowly, we can discard its response
        //Set the class variable to this value
        this.LastAutoCompleteRequestGUID = this.globalFunctions.GenerateFastGUID();
        //Make a copy of it for sending to the update request
        const copyOfGUID = JSON.parse(JSON.stringify(this.LastAutoCompleteRequestGUID));

        this.AutoComplete_RequestData(requestType, dataUnit.Name, controlValue, dataUnit.AutoCompleteControlData, copyOfGUID);
      }
    }
    else {
      if (requestType === "ControlGUID") {
        //Request new data and update the current value so we don't request it again.
        dataUnit.AutoCompleteLastSearchedValue = controlValue;
        //console.log("ReturnAutoCompleteData is requesting data");

        //Let's timestamp this request. that way, when a old update responds slowly, we can discard its response
        //Set the class variable to this value
        this.LastAutoCompleteRequestGUID = this.globalFunctions.GenerateFastGUID();
        //Make a copy of it for sending to the update request
        const copyOfGUID = JSON.parse(JSON.stringify(this.LastAutoCompleteRequestGUID));

        this.AutoComplete_RequestData(requestType, dataUnit.Name, controlValue, dataUnit.AutoCompleteControlData, copyOfGUID);
      }
    }

    //Just return the array, even though its not synchronous. the update will come later and update it. (i think?)
    if (dataUnit.AutoCompleteControlData != null) {
      return dataUnit.AutoCompleteControlData;
    }
    else {
      //console.log("Nothing to return : ", controlValue);
    }
  }

  //Requests autocomplete data from the server
  private AutoComplete_RequestData(requestType: string, controlType: string, requestValue: string, controlArray: ControlData[], autoCompleteRequestGUID: string): void {
    //Construct a AutoComplete request body that we can post to the server
    const apiRequest = { RequestType: requestType, ControlType: controlType, SearchValue: requestValue };

    //Call the server, if we have some value to request
    if (controlType != null) {
      this.apiService.APIData_Post(this.apiService.Endpoints.StaticDataController, StaticDataControllerMethods[StaticDataControllerMethods.AutoComplete], apiRequest)
        .subscribe(apiResponse => {
          if (apiResponse === null) { return; }
          else {
            //Check if we are the latest request, only process the response if so.
            if (autoCompleteRequestGUID === this.LastAutoCompleteRequestGUID) {
              this.AutoComplete_ProcessResponse(apiResponse, controlArray);
            }
          }
        });
    }
  }

  //Processes the autocomplete data returned by the server and pushes it into the array
  private AutoComplete_ProcessResponse(apiResponseData: ControlData[], controlArray: ControlData[]): void {
    if (!this.globalFunctions.isEmpty(apiResponseData)) {
      //Reset the array, then chuck the data in
      controlArray.length = 0;

      const results = apiResponseData;
      for (const key in results) {
        const ControlDataUnit = {
          Entity: results[key].Entity,
          ControlType: results[key].ControlType,
          ControlGUID: results[key].ControlGUID,
          ControlValue: this.globalFunctions.HTMLUnescape(results[key].ControlValue),
          ControlDisplay: ""
        };
        //Push the result in to the control array by ref
        controlArray.push(ControlDataUnit);
      }
    }
    else {
      //No results! empty out the array			
      controlArray.length = 0;
    }
  }

  //Used to validate key presses on editing textareas, and also save the value into the dataunit for sending to the server for the request later
  public TextArea_OnKeyPress(event: Event, eventType: string, dataUnit: DataUnit) {
    //console.log('key pressed, input value: ', event.target.value);
    //Where was this character inserted? at event.target.selectionStart
    const eventTarget = event.target as HTMLInputElement;
    const characterIndexInserted = eventTarget.selectionStart as number;

    //Check the data type, and perform the necessary removal of invalid characters using regex. except string, that has no regex
    if (!dataUnit.Type.includes('string')) {
      let regExType = new RegExp('');
      if (dataUnit.Type == 'integer') {
        regExType = this.globalFunctions.regExInteger;
      }
      else if (dataUnit.Type == 'percent') {
        regExType = this.globalFunctions.regExPercent;
      }
      else if (dataUnit.Type.includes('decimal.2')) {
        //Decimal can have differing precision. pick the right one
        regExType = this.globalFunctions.regExDecimal2;
      }
      else if (dataUnit.Type.includes('decimal.4')) {
        regExType = this.globalFunctions.regExDecimal4;
      }
      else if (dataUnit.Type == 'currency') {
        //Updating the format in place is a lot of work. requires us to store the original unformatted string, and a pretty version.
        //Code that i have done before in xChange mobile. will come back to it if desired. for now lets just use in place, with regex (no formatting applied)
        regExType = this.globalFunctions.regExCurrency;
        //Formatting? i think we may show a separate formatted display to the right? TO DECIDE later
      }
      else if (dataUnit.Type.includes('currency.8')) {
        //Higher precision currency
        regExType = this.globalFunctions.regExCurrency8;
      }

      //Now perform the regex test
      if (!regExType.test(eventTarget.value)) {
        //Didn't pass regex, remove the last character
        eventTarget.value = this.globalFunctions.removeStringByIndex(eventTarget.value, characterIndexInserted);
        //Try to set the cusor back to where it was
        eventTarget.selectionStart = characterIndexInserted - 1;
        eventTarget.selectionEnd = characterIndexInserted - 1;

        //Let's parse it into a pretty text that we can display to the user
        let prettyType: string;
        prettyType = JSON.parse(JSON.stringify(dataUnit.Type));
        let precision = '';
        let prettyTypeDescription = '';

        if (prettyType.includes('.')) {
          const splitted = prettyType.split('.');
          prettyType = splitted[0];
          precision = splitted[1];
          prettyTypeDescription = prettyType + " with a maximum of " + precision + " decimal places";
        }
        else {
          prettyTypeDescription = prettyType;
        }
        //Now give the user this error message
        this.notifyService.Error_Show("An invalid value was entered. Please review and try again", "Not a valid " + prettyTypeDescription)
      }
      else {
        //We passed regex. lets put this value into the dataunit value, so that it can be saved later
        dataUnit.Value = eventTarget.value;
      }
    }
    else {
      //String has no regex, just set the value so that it can be saved
      dataUnit.Value = eventTarget.value;
    }
  }

  //Get valid filetypes
  public ValidFileTypes_Get() {
    return this.globalFunctions.GetAllowedFileTypes();
  }

  //For passing the context to ng-template for rendering the screenshot
  public ImageTemplateContext_Get(fileName: string, index) {
    return {
      screenshotID: this.ScreenshotsPath[index] + fileName,
      fileExtension: this.FileExtension
      //Index: security.Index
    }
  }
}

